import modalSignup from '~/mixins/modalSignup.js'

export default {
  mixins: [modalSignup],

  props: {
    signUpSubMenu: Object,
    signInSubMenu: Object,
    isDropdownOpen: Boolean,
    fixedBottom: {
      type: Boolean,
      default: false,
    },
  },
}
