import hotIcon from '~/components/Molecules/Icon/Icon.vue'

export default {
  components: {
    hotIcon,
  },

  props: {
    label: {
      type: String,
    },

    description: {
      type: String,
    },

    href: {
      type: String,
    },

    image: {
      type: Object,
    },

    type: {
      type: String,
    },

    tag: {
      type: String,
    },

    target: {
      type: String,
      default: '',
    },
  },

  computed: {
    isWideCard() {
      return this.type === 'wide'
    },

    isVideoCard() {
      return this.type === 'video'
    },

    isDefaultCard() {
      return !Boolean(this.type)
    },

    hasTag() {
      return Boolean(this.tag)
    },
  },
}
