import hotIcon from '~/components/Molecules/Icon/Icon.vue'

export default {
  components: {
    hotIcon,
  },

  props: {
    navigation: Object,
    sectionOpen: Boolean,
    dropdownIsOpen: Boolean,
    hideBack: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    back() {
      this.$parent.back()
    },

    close() {
      this.$parent.close()
    },
  },
}
