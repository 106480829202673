import hotIcon from '~/components/Molecules/Icon/Icon.vue'
import organicCardFeaturedMenuItem from '~/components/Molecules/Organic/CardFeaturedMenuItem/CardFeaturedMenuItem.vue'
import headerBarMenu from '~/components/Organisms/Organic/HeaderBarMenu/HeaderBarMenu.vue'
import footerBarMenu from '~/components/Organisms/Organic/FooterBarMenu/FooterBarMenu.vue'

export default {
  components: {
    hotIcon,
    organicCardFeaturedMenuItem,
    headerBarMenu,
    footerBarMenu,
  },

  props: {
    submenu: {
      type: [Array, Object],
      required: true,
    },

    isOpen: {
      type: Boolean,
      required: true,
    },

    plattaform: Object,
  },

  data() {
    return {
      index: 0,
      isSectionOpen: false,
    }
  },

  computed: {
    selectedItem() {
      return this.submenu[this.index]
    },

    hasListItems() {
      let listItems = this.selectedItem.itens.reduce((acc, item) => !item.image, 0)

      return listItems > 0
    },
  },

  methods: {
    back() {
      if (this.isSectionOpen) {
        this.isSectionOpen = false

        return
      }

      if (this.isOpen) {
        this.$emit('close-dropdown', false)

        return
      }

      this.close()
    },

    close() {
      this.isSectionOpen = false
      this.index = 0
      this.$emit('close-menu', false)
    },

    selectItem(index) {
      this.index = index
      this.isSectionOpen = true
    },
  },
}
