
import hotHeader from '~/components/Organisms/Header/Header.vue'
import hotOrganicHeader from '~/components/Organisms/Organic/Header/Header.vue'
import hotOrganicNewHeader from '~/components/Organisms/Organic/NewHeader/NewHeader.vue'
import hotHeaderSidebar from '~/components/Organisms/HeaderSidebar/HeaderSidebar.vue'
import hotFooter from '~/components/Organisms/Footer/Footer.vue'
import stripe from '~/mixins/stripe.js'
import Provider from '~/services/provider'

export default {
  mixins: [stripe],

  components: {
    hotHeader,
    hotOrganicHeader,
    hotHeaderSidebar,
    hotFooter,
    hotOrganicNewHeader,
  },

  head() {
    return {
      bodyAttrs: {
        class: [
          this.$route.path === '/' ? 'root-page' : `root-lang-${this.$i18n.locale}`,
          this.hasActivateExperiment ? 'vwo-page' : 'not-has-test',
        ],
      },
    }
  },

  computed: {
    hasActivateExperiment() {
      return Boolean(['es-es', 'es'].includes(this.$i18n.locale) && (this.isHomepage || this.isAffiliate))
    },

    isSupersonicHome() {
      return Boolean(['pt-br', 'es-co', 'es-mx', 'es', 'es-es'].includes(this.$i18n.locale) && this.isHomepage)
    },

    isNewHome() {
      return Boolean(['es', 'es-co', 'es-mx'].includes(this.$i18n.locale) && this.isHomepage)
    },

    isNewHeaderPage() {
      return Boolean(
        [
          'index___pt-br',
          'affiliates___pt-br',
          'hotmart-app___pt-br',
          'solutions___pt-br',
          'payments___pt-br',
          'about___pt-br',
          'members-area___pt-br',
          'interested-producer___pt-br',
        ].includes(this.$route.name) || this.isNewSpanishHomeTest
      )
    },

    isNewSpanishHomeTest() {
      if (['index___es-es'].includes(this.$route.name)) {
        return this.$growthbook.isOn('7_corporate_website_home_all_devices_spain_new_es_es_home_layout')
      }

      return false
    },

    isHomepage() {
      return Boolean(this.$route.name && this.$route.name.includes('index'))
    },

    isAffiliate() {
      return Boolean(this.$route.name && this.$route.name.includes('affiliates'))
    },

    breadcrumbs() {
      const payload = {
        routePath: this.$route.path,
        dinamicValue: this.$route.name && this.$route.name.includes('tour-id') ? 'Video' : false,
      }

      const list = Provider.breadcrumbs.getItemList(payload)

      return list
    },
  },
}
