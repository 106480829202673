import viewport from '~/mixins/viewport'
import signupButton from '~/components/Molecules/OrganicSquad/SignupButton/SignupButton.vue'
import hotLink from '~/components/Atoms/Link/Link.vue'
import hotOrganicCountdown from '~/components/Molecules/Organic/Countdown/Countdown.vue'

export default {
  mixins: [viewport],

  components: {
    signupButton,
    hotLink,
    hotOrganicCountdown,
  },

  data() {
    return {
      canShowTopStripe: true,
    }
  },

  props: {
    content: Object,
    layout: Object,
    countdown: Object,

    name: {
      type: String,
      required: true,
    },

    isCentered: {
      type: Boolean,
      required: true,
    },

    links: {
      type: Object,
      required: true,
    },

    image: Object,

    labels: {
      type: Object,
      required: true,
    },

    modal: Object,

    isPopup: Boolean,
  },

  methods: {
    openModal(e, data) {
      e.preventDefault()
      const modalOptions = {
        component: Typeform,
        data: this.modal,
        locale: this.$i18n.locale,
      }

      this.$modal.open(modalOptions)
    },

    closeTopStripe() {
      this.canShowTopStripe = false
    },
  },

  computed: {
    hasCountdown() {
      return Boolean(this.countdown && this.countdown.enable)
    },

    pageName() {
      return this.$route.name
    },

    hasImage() {
      return Boolean(this.image)
    },

    centered() {
      const prefix = 'top-striper'

      return isCentered ? `${prefix}--centered` : `${prefix}--not-centered`
    },

    responsiveTextClass() {
      return ['es-es'].includes(this.$i18n.locale) ? 'top-stripe__text--responsive' : ''
    },

    link() {
      const { mobile, desktop } = this.links

      return this.isMobile ? mobile : desktop
    },

    selectedComponent() {
      return 'hotLink'
    },

    selectedProps() {
      return this.link
    },
  },
}
