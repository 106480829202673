export default {
  async setMenuOpen({ commit }, payload) {
    return commit('SET_MENU_OPEN', payload)
  },

  async setDropdownOpen({ commit }, payload) {
    return commit('SET_DROPDOWN_OPEN', payload)
  },

  async setSectionOpen({ commit }, payload) {
    return commit('SET_SECTION_OPEN', payload)
  },
}
