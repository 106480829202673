export default {
  components: {
    hotIcon: () => import('~/components/Molecules/Icon/Icon.vue'),
  },

  props: {
    label: String,
    links: Array,
  },

  data() {
    return {
      isOpen: false,
    }
  },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener('click', this.click)
    })
  },

  beforeDestroy() {
    window.removeEventListener('click', this.click)
  },

  methods: {
    toggleOpen() {
      this.isOpen = !this.isOpen
    },

    close() {
      this.isOpen = false
    },

    click() {
      if (this.isOpen) this.close()
    },
  },
}
