export default {
  SET_MENU_OPEN(state, payload) {
    state.menuOpen = payload
  },

  SET_DROPDOWN_OPEN(state, payload) {
    state.dropdownOpen = payload
  },

  SET_SECTION_OPEN(state, payload) {
    state.sectionOpen = payload
  },
}
