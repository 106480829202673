import { mapActions } from 'vuex'

export default {
  methods: {
    ...mapActions('modalSignup', ['setSignupModalOpen']),

    openModal() {
      document.body.classList.toggle('modal-open')
      this.setSignupModalOpen(true)
    },
  },
}
