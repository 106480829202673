import { render, staticRenderFns } from "./TopStripe.vue?vue&type=template&id=0cf6fe13&scoped=true"
import script from "./TopStripe.js?vue&type=script&lang=js&external"
export * from "./TopStripe.js?vue&type=script&lang=js&external"
import style0 from "./TopStripe.scss?vue&type=style&index=0&id=0cf6fe13&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0cf6fe13",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HotImage: require('/runner/_work/app-hotmart/app-hotmart/components/Atoms/Image/Image.vue').default})
